/* CSS for nora.ai */

// x-styles
@import "../../../../../../../lib/x1/css/x-0.less";

// local imports
@import "variables.less";
@import "fonts.less";

@import "../../../../../../groups/all/src/resources/css/consent.less";
@responsiveConsentBreakpoint: ~"only screen and (max-width: 768px)";
@tabletConsentBreakpoint: ~"only screen and (max-width: 1070px)";

// limit number of text-lines for list/feed items
// due to occurences of some very long introductions
.mixin-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#global-sub-menu,
.left-menu-wrapper,
.vrtx-subscribe-wrapper-inner.vrtx-dropdown-wrapper-inner,
.vrtx-feed-link {
  display: none;
}

// Main

#total-main {
  h1 {
    font-weight: 600;
  }
}

// Header

#header {
  padding-left: 0px;
  padding-right: 0px;
  background-color: var(--color-light-blue);

  #header-tools {
    .toggle-search.header-search-expand {
      border: 1px solid var(--color-dark-blue);
      border-radius: 10px;
      padding: 10px 16px;
    }

    .sidebar-menu-toggle {
      background-color: var(--color-dark-blue);
      padding: 10px 16px;
      border-radius: 10px;
      span {
        color: white;
      }

      &::before {
        background: url("../images/menu-button-white.svg") no-repeat center;
        color: white;
      }
    }

    #header-language {
      span {
        border-radius: 10px;
        padding: 3px 8px;
      }
    }
  }

  .logo {
    width: 150px;
  }
}

// Footer

#footer-wrapper {
  background: url("../images/footer.svg") no-repeat center bottom
    var(--color-dark-blue);
  background-size: 100% 170px;

  .row {
    gap: 40px 5%;
  }

  #footers {
    margin-bottom: 90px;

    p,
    span,
    a {
      color: white;
    }

    .vrtx-dropdown-wrapper a {
      color: black;
    }
  }

  .footer-content {
    gap: 40px 5%;

    h2 {
      color: white;
    }
  }

  .vrtx-social-list {
    margin-top: 20px;
  }
}

#footer-logo {
  background-color: transparent;
  padding-bottom: 35px;
  margin: 0;
  grid-gap: 0;

  a {
    background: url("../images/footer-logo.svg") no-repeat;
    background-size: contain;
    width: 250px;
    height: 60px;
  }
}

// General

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-1);
  color: var(--color-dark-blue);
}

p,
span,
blockquote {
  line-height: var(--line-height-2);
  color: var(--color-dark-blue);
}

body {
  background-color: var(--color-light-blue);
  font-family: Poppins, Helvetica, sans-serif;
}

h1 {
  font-size: var(--font-size-h1);
  margin: 40px 0px 40px 0px;

  @media (@screen-large) {
    hyphens: manual;
  }
}

p {
  font-style: normal;
  font-weight: 400;
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: 500;
}

// Customizable colors for various elements.
.custom-color-styling(@backgroundColor, @fontColor, @icon) {
  &.img-special-left,
  &.img-special-right,
  &.box-special,
  &.link-special {
    background-color: @backgroundColor;
    color: @fontColor;

    h2,
    p,
    a,
    span {
      color: @fontColor;
    }

    a::before {
      content: "";
      background: @icon no-repeat center;
      background-size: contain;
      padding-right: 24px;
      margin-right: 15px;
    }

    &.link-special {
      a::before {
        content: none;
      }
      a::after {
        content: "";
        position: absolute;
        background: var(--color-teal) @icon no-repeat center;
        background-size: 40px auto;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        margin-left: 20px;
        margin-top: 15px;
      }
    }
  }

  &.navigation-links {
    a {
      background-color: @backgroundColor;
      color: @fontColor;

      &::after {
        content: "";
        background: @icon no-repeat center;
        background-size: contain;
      }
    }

    //only when no background
    & when(@backgroundColor = transparent) {
      li a {
        background-color: @backgroundColor;
        color: @fontColor;
        display: flex;
        align-items: center;

        &::after {
          display: none;
        }

        &::before {
          content: "";
          background: @icon no-repeat center;
          background-size: contain;
          padding-right: 24px;
          margin-right: 31px;
          flex-shrink: 0;
          height: 20px;
          width: 26px;
        }
        &:hover {
          text-decoration: underline;
          border-color: transparent;
        }
      }
    }
  }

  &.half-box-left,
  &.half-box-right {
    p:last-child {
      background-color: @backgroundColor;
      color: @fontColor;
      padding: 22px 50px 20px;
      border-radius: 20px;
      width: fit-content;

      a {
        text-decoration: none;

        &::after {
          content: "";
          background: @icon no-repeat center;
          background-size: contain;
          padding-right: 48px;
          margin-right: 15px;
        }
      }
    }
  }

  // Feed
  .vrtx-feed ul li {
    background-color: @backgroundColor;
    color: @fontColor;
  }

  // Events
  .vrtx-event-component .vrtx-event-component-main .vrtx-list-item-content {
    background-color: @backgroundColor;
    color: @fontColor;
  }
}

#main .dark-blue {
  .custom-color-styling(var(--color-dark-blue), var(--color-white),url("../images/arrow-white.svg"));
}

#main .dark-grey {
  .custom-color-styling(var(--color-dark-grey), var(--color-white),url("../images/arrow-white.svg"));
}

#main .deep-blue {
  .custom-color-styling(var(--color-deep-blue), var(--color-aquamarine),url("../images/arrow-aquamarine.svg"));
}

#main .light-green {
  .custom-color-styling(var(--color-light-green), var(--color-black),url("../images/arrow-black.svg"));
}

#main .midnight-blue {
  .custom-color-styling(var(--color-navy-blue), var(--color-light-green),url("../images/arrow-light-green.svg"));
}

#main .navy-blue {
  .custom-color-styling(var(--color-navy-blue), var(--color-white),url("../images/arrow-white.svg"));
}

#main .navy-blue {
  .custom-color-styling(var(--color-royal-blue), var(--color-white),url("../images/arrow-white.svg"));
}

#main .purple {
  .custom-color-styling(var(--color-purple), var(--color-yellow),url("../images/arrow-yellow.svg"));
}

#main .sky-blue {
  .custom-color-styling(var(--color-sky-blue), var(--color-black),url("../images/arrow-black.svg"));
}

#main .teal {
  .custom-color-styling(var(--color-teal), var(--color-white),url("../images/arrow-white.svg"));
}

#main .aquamarine {
  .custom-color-styling(var(--color-aquamarine), var(--color-black),url("../images/arrow-black.svg"));
}

#main .yellow {
  .custom-color-styling(var(--color-yellow), var(--color-black),url("../images/arrow-black.svg"));
}

#main .no-background {
  .custom-color-styling(transparent, var(--color-extra-dark-blue), url("../images/arrow-dark-blue.svg"));
}



// -specials
.vrtx-frontpage-box {
  &.img-special-left,
  &.img-special-right {
    border-radius: 20px;
    overflow: hidden;
    h2 {
      font-weight: 500;
      font-size: var(--font-size-h2);
    }
    p {
      font-weight: 400;

      font-size: var(--font-size-body);
    }
    a {
      font-size: var(--font-size-body-lenketekst);
      font-weight: 400;
    }
  }

  // box-special
  &.box-special {
    .vrtx-box-content {
      padding: 0;
    }
    border-radius: 20px;
    padding: 60px 60px;

    h2 {
      font-size: var(--font-size-h2);
      font-weight: 500;
      margin-bottom: 20px;
      text-align: left;
      letter-spacing: 0em;
    }

    p {
      font-size: var(--font-size-body);
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    a {
      font-size: var(--font-size-body-lenketekst);
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: none;
    }
  }

  &.link-special {
    font-weight: 600;
    font-size: var(--font-size-h1);
    border-radius: 20px;
    border: none;
    padding: 60px 60px;
  }
}



// Navigation links
.navigation-links {
  padding: 70px 0 90px;
  & li a {
    padding: 20px;
    display: block;
    max-width: 452px;
    //max-width: none;
    border-radius: 20px;

    &::after {
      top: 20px;
      width: 28px;
    }
  }
}

// Feed
.vrtx-feed ul li {
  border-radius: 20px;
  overflow: hidden;

  .vrtx-list-item-content {
    // if no image
    &:first-child {
      margin-top: 30px;
    }

    .item-title {
      font-size: var(--font-size-h4);
      font-weight: 500;
    }

    .item-description {
      font-size: var(--font-size-body);
      font-weight: 400;
    }

    p {
      // limiting number of lines
      .mixin-line-clamp;
      // if multiple paragraphs, hide all but first
      &:not(:first-child) {
        display: none;
      }
    }
  }
}

.the-frontpage {
  #vrtx-main-content {
    background: url(../images/background-illustration-brain.svg) top right
      no-repeat;

    .header-illustration-container {
      margin-bottom: 120px;

      #header-illustration-title {
        margin-top: 70px;
        font-size: var(--font-size-h1);
        color: var(--color-dark-blue);
        hyphens: none;
      }

      #header-learn-more {
        font-size: var(--font-size-body-lenketekst);
        font-weight: 400;
        color: var(--color-dark-blue);
      }
    }

    @media (@screen-below-oversized) {
      background-size: calc(100% - 60vw);

      .header-illustration-container {
        margin-bottom: calc(10vw - 120px);
      }
    }

    @media (@screen-below-large) {
      .column-wrapper.wide-narrow {
        grid-template-columns: 3fr 2fr;
      }
    }

    @media (@screen-below-small) {
      background: initial;
      .column-wrapper.wide-narrow {
        grid-template-columns: 1fr;
      }
    }
  }
}

.frontpage-introduction-text {
  font-size: var(--font-size-h2-small);
  font-weight: 400;
  color: var(--color-dark-blue);
  hyphens: none;
}

.frontpage-links {
  padding-top: 0;
  padding-bottom: 20px;
}

.vrtx-frontpage-box {
  h2 {
    margin-bottom: 40px;
    font-weight: 500;

    a {
      text-decoration: none;
    }
  }
}

// Frontpages but not the main frontpage
#vrtx-frontpage:not(.the-frontpage) {
  #vrtx-main-content {
    background: url(../images/background-illustration1.svg) no-repeat;
    background-position: center 13.5%;
    background-size: 100% 370px;
  }
}

// Div that fill available space
.flexible-div {
  flex: 1;
}

// Article
.vrtx-article-body {
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-light-grey);
  padding-bottom: 40px;

  @media (@screen-large) {
    hyphens: manual;
  }

  blockquote {
    color: var(--color-navy-blue);
    margin: 80px -280px 80px 0;

    p {
      font-weight: 500;
      color: var(--color-navy-blue);
    }
  }
}

// Introduction
.vrtx-introduction {
  p {
    font-size: var(--font-size-intro-ingress);
  }
  @media (@screen-large) {
    hyphens: manual;
  }
}

#vrtx-structured-article {
  .vrtx-introduction-image {
    .vrtx-imagetext {
      margin-top: 10px;
    }
  }

  #facts-color-1 {
    background: var(--color-yellow);
    border-radius: 20px;
    margin-right: -340px;
    margin-top: 40px;
    width: 315px;
    font-size: var(--font-size-body);
    font-weight: 700;
    color: var(--color-dark-blue);

    @media (@screen-below-oversized) {
      float: unset;
      margin-left: 0;
      margin-right: 0;
      width: unset;
    }
  }
}

// Main content time and date

.vrtx-authors,
.vrtx-date-info span {
  color: var(--color-light-grey);
  font-weight: 300;
}

// News

.vrtx-list-item-content {
  .item-title,
  .vrtx-title,
  .vrtx-event-component-title {
    font-size: var(--font-size-h2-small);
    font-weight: 500;
  }

  grid-template-rows: min-content min-content;

  .vrtx-time-and-place-container {
    order: 1;
  }

  .introduction {
    order: 2;
  }
}

.vevent,
.vrtx-event-component,
.with-border-radius,
.news-feed {
  img {
    border-radius: 20px;
  }
}

.vrtx-resource.vrtx-default-article {
  img {
    border-radius: 20px;
  }
}

// Rows

.grid-container {
  margin-bottom: 90px;
  &.row-all-colored {
    background: none;
    padding: 0;
    margin-bottom: 90px;
  }
}

.grid-container.dark-blue {
  background-color: var(--color-navy-blue);
  padding: 70px 0 90px;
  * {
    color: var(--color-light-green);
    & a {
      background-color: #ffffff;
      color: var(--color-dark-blue);
    }
  }
}

// Event item

.vrtx-byline {
  display: flex;
  flex-direction: column;

  @media (@screen-xlarge) {
    flex-direction: row;
  }

  .vrtx-time-and-place-container,
  .vrtx-event-organizers {
    box-sizing: border-box;
    border-left: 3px solid var(--color-aquamarine);
    padding-left: 20px;
  }

  // Time

  .vrtx-time-and-place-container {
    margin-left: 20px;
    margin-right: 30px;

    @media (@screen-below-xlarge) {
      margin-left: 0;
    }

    .time-and-place {
      font-size: var(--font-size-body);
      font-weight: 500;
    }

    .separator-time-and-place {
      display: none;
    }

    font-size: var(--font-size-body);
    font-weight: 400;
  }

  // Organizer

  .vrtx-event-organizers {
    align-self: flex-start;

    h2 {
      font-size: var(--font-size-h4);
      font-weight: 500;
    }

    span {
      display: block;
      font-size: var(--font-size-body);
      font-weight: 400;
    }
  }
}

// Accordion

h2,
h3 {
  &.accordion {
    position: relative;
    width: auto;
    padding-left: 0px; // space for arrow
    margin-bottom: 15px;
    cursor: pointer;
    &::after {
      content: "";
      background: url("../images/chevron.svg") no-repeat center;
      background-size: 10px auto;
      position: absolute;
      right: 0;
      width: 20px;
      height: 1.25em; // Font-size dependent to align vertically
      transition: all 0.3s;
    }
    &:hover {
      text-decoration: underline;
    }
    &::before {
      display: none;
    }
  }
}

details.accordion-wrapper {
  summary {
    list-style: none;
    width: auto;
  }
  &[open] {
    height: var(--expanded); // js-controlled
    summary {
      .accordion {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.info-box,
.info-box.row-all-colored {
  width: 1162px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 59px;
  border-radius: 20px;
  background-color: #c4e5f8;
}

// Strategic partners and startup partners

.partner-logos .vrtx-box-content {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 190px;
  gap: 20px;
  justify-content: center;

  @media (@screen-xsmall) and (@screen-below-small) {
    gap: 15px;
  }

  p {
    width: 165px;
    height: 110px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 2px 2px 15px 2px rgba(169, 169, 169, 0.1);
    border-radius: 8px;

    &:focus-within {
      outline: -webkit-focus-ring-color auto 1px;
    }

    a.partner-logo img {
      width: 100%;
      height: 100%;
      padding: 8px;
      object-fit: contain;
      background-color: white;
      filter: grayscale(100%);
    }

    &:hover {
      border-radius: 8px;
      transform: translateY(-5px);
      box-shadow: 0px 4px 8px 0px var(--color-navy-blue);
    }
  }
}

// vrtx-person-listing table
.vrtx-person-listing {
  caption {
    display: none;
  }

  thead tr {
    display: flex;

    .vrtx-person-listing-name,
    .vrtx-person-listing-email,
    .vrtx-person-listing-phone {
      font-size: var(--font-size-h4);
      font-weight: 600;
      display: flex;
      flex: 1;
      justify-content: center;
      align-self: center;
    }

    .vrtx-person-listing-name {
      flex-basis: 400px;
    }

    .vrtx-person-listing-phone {
      order: 2;
    }

    .vrtx-person-listing-tags {
      display: none;
    }
  }

  tr[class^="vrtx-person-"] {
    &:nth-child(odd) {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.75) 0%,
          rgba(255, 255, 255, 0.75) 100%
        ),
        var(--color-sky-blue);
    }

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    td.vrtx-person-listing-name a.vrtx-image {
      filter: grayscale(100%);
    }

    @media (@screen-large) {
      display: flex;

      td.vrtx-person-listing-name {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-left: 250px;
        height: 232px;
        flex-basis: 400px;
        justify-content: center;
      }

      td.vrtx-person-listing-name a.vrtx-image {
        border: none;
        width: 200px;
        height: 200px;
      }

      td.vrtx-person-listing-name a.vrtx-image img {
        border-radius: 10px;
      }

      // Name of the employee
      td.vrtx-person-listing-name a:nth-child(2) {
        font-size: var(--font-size-h2-small);
      }
      td.vrtx-person-listing-name span {
        font-size: var(--font-size-h3-small);
      }

      td.vrtx-person-listing-email,
      td.vrtx-person-listing-phone {
        display: flex;
        flex: 1;
        align-self: center;
        justify-content: center;
      }

      td.vrtx-person-listing-tags {
        display: none;
      }

      td.vrtx-person-listing-phone {
        order: 2;
      }
    }
  }
}

// Employee details page
#vrtx-person #vrtx-main-content {
  h1 {
    color: var(--color-black);
    font-size: var(--font-size-h1-small);
    margin-bottom: 0px;
  }

  h2 {
    color: var(--color-black);
  }

  .vrtx-person-position span {
    font-size: var(--font-size-h2-small);
  }

  #vrtx-person-contact-info-wrapper {
    position: relative;
    border-radius: 20px;
    background-color: var(--color-sky-blue);
    max-width: var(--width-soft-max);
    margin: 40px 0;
    padding: 40px;
    @media (@screen-large) {
      min-height: 500px; // 2/3 image ratio (unless min-height exceeded by text content)
      padding-left: 400px; // Space for image
      &:not(:has(img)) { // Reverse :has condition for img. Will leave space if unsupported.
        padding-left: 40px;
      }
    }
    @media (@screen-below-medium) {
      padding: 20px;
    }

    .vrtx-person-image {
      border-radius: 20px;
      filter: grayscale(100%);
      @media (@screen-large) {
        position: absolute;
        left: 0;
        top: 0;
        width: 333px; // 2/3 image ratio. controlled by wrapper min-height.
        height: 100%; // fill wrapper height
        object-fit: cover;
      }
    }

    #vrtx-person-contact-info-extras {
      display: flex;
      flex-direction: column;
      font-size: var(--font-size-h4);

      #vrtx-person-vcard {
        order: 1;
      }

      #vrtx-press-photo {
        order: 2;
      }
    }

    @media (@screen-large) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 350px;
      margin-top: 30px;

      .vrtx-person-image {
        width: 350px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      #vrtx-person-contact-info-extras {
        flex-direction: row;
        align-items: center;
        gap: 30px;
        white-space: nowrap;
      }
    }

    .vrtx-person-contact-info-line.vrtx-email,
    .vrtx-person-contact-info-line.vrtx-mobile {
      .vrtx-label {
        font-size: var(--font-size-body);
        line-height: var(--line-height-3);
      }
      .vrtx-value {
        font-size: var(--font-size-h3-small);
        line-height: var(--line-height-3);
      }
    }

    .vrtx-person-contact-info-line.vrtx-username,
    .vrtx-person-contact-info-wrapper-end {
      display: none;
    }
  }

  // Employee details page - text
  #vrtx-person-main-content-wrapper {
    margin: 0 auto;
    .vrtx-article-body {
      border-bottom: none;

      h2 {
        font-size: var(--font-size-h3);
        line-height: var(--line-height-1);
        white-space: normal;
      }

      ul {
        font-size: var(--font-size-body);
        line-height: var(--line-height-2);

        li {
          margin-bottom: 20px;
        }
      }
    }

    .vrtx-date-info {
      display: none;
    }
  }
}

// Nora person listing - bullet list
.person-listing-list {
  li {
    margin-bottom: 20px;
  }
}

// Nora person listing grid-like
.nora-person-listing {
  .vrtx-box-content {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 40px 40px;
    justify-content: center;
    position: relative;
  }

  .nora-person-wrapper {
    align-items: center;
    width: 315px;
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
    color: var(--color-dark-blue);

    // Screen max-width: 600px
    @media (@screen-below-small) {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.75) 0%,
          rgba(255, 255, 255, 0.75) 100%
        ),
        var(--color-sky-blue);
      border-radius: 20px;
      padding: 20px;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 4px 8px 0px var(--color-navy-blue);
    }

    p {
      margin: 0px 0px;

      //1st <p> is an image
      //2nd <p> the name
      &:nth-child(n + 3):not(:last-child) {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      //Name of the person
      &:nth-child(2) {
        margin-bottom: 5px;
        font-size: var(--font-size-h2);
        font-weight: 500;
        letter-spacing: 0;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        margin-top: 5px;
      }
    }

    img {
      display: initial;
      position: relative;
      width: 300px;
      object-fit: cover;
      aspect-ratio: 1/1;
      filter: grayscale(100%);
      border-radius: 10px;
    }

    a {
      text-decoration: none;
    }
  }
}

.all-messages {
  border-bottom: 0.5px solid var(--color-dark-grey);
  padding-bottom: 4px;
  font-size: var(--font-size-h3-small);
  font-weight: 400;
  margin-top: 10px;

  a:hover {
    text-decoration: none;
  }
}

.box-flex {
  display: flex;
  justify-content: space-between;
  min-width: 820px;
}

// Facts container
.vrtx-facts-container.facts-container-color1 {
  background-color: var(--color-sky-blue);
  border-radius: 20px;
  padding: 50px 60px 35px 60px;

  a,
  p {
    font-size: var(--font-size-body);
    font-weight: 400;
  }
}

.slim-picture {
  margin-bottom: 0;
}

// Newsletter
.newsletter-signup {
  @media (@screen-below-medium) {
    margin-right: 0;
  }

  button {
    padding: 8px 10px;
    background-color: var(--color-light-green);
    color: var(--color-dark-blue);
    font-size: var(--font-size-body);
    font-weight: 500;
    border-radius: 10px;

    &::after {
      content: "";
      background: url("../images/arrow-small.svg") no-repeat right;
      padding-left: 32px;
      height: 10px;
      display: inline-block;
    }
  }
}

// Event type tags
.event-type :nth-child(n + 2) {
  display: none;
}

.event-type span {
  background-color: var(--color-teal);
  border-radius: 10px;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 15px;
  font-size: var(--font-size-body-small);
  font-weight: 400;
  color: white;
  text-align: center;

  &::before {
    content: "";
    background: url("../images/dot-white.svg") no-repeat center;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 8px;
  }
}

.vrtx-event-component-title {
  .summary {
    font-size: var(--font-size-h3);
    font-weight: 500;
  }
}

.nora-strategy.img-special-right {
  background-color: var(--color-light-green);

  border-radius: 20px;
  .vrtx-frontpage-box-picture {
    position: relative;

    img {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }

  p {
    color: var(--color-deep-blue);
  }
}

.main .vrtx-subfolder-menu {
  display: none; // ?
  border-radius: 20px;
  background-color: var(--color-dark-blue);
  color: var(--color-light-blue);
  .menu-title {
    display: none;
  }
  a {
    &::before {
      background: url("../images/arrow-white.svg") no-repeat center;
      margin-left: 10px;
      background-size: contain;
    }
  }
}

.list-space {
  ul li {
    padding-bottom: 15px;
  }
}

.info-box.person-info {
  .vrtx-frontpage-box.vrtx-frontpage-box-picture {
    position: relative;
    padding: 10px;

    > .vrtx-frontpage-box-picture {
      float: left;
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .vrtx-box-content {
      &:before {
        content: "";
        display: block;
      }
      > .picture-text {
        display: inline-block;
        vertical-align: top;
        width: 45%;
      }
    }
  }
}

// Text placement
#custom-text-placement {
  color: var(--color-teal);
  float: right;
  margin-right: -340px;
  margin-left: 25px;
  margin-bottom: 20px;
  width: 315px;
  clear: right;
  border-radius: 20px;
  margin-top: 15px;
  font-style: italic;

  @media (@screen-below-oversized) {
    float: unset;
    margin-left: 0;
  }
}

// Event subpage
#eventListing\.ongoingEvents,
#eventListing\.upcomingEventsSearchComponent,
#eventListing\.previousEventsSearchComponent {
  &:not(:last-child) {
    margin-bottom: 80px;
  }

  h2 {
    font-size: var(--font-size-h4);
    font-weight: 500;
    color: var(--color-dark-grey);
  }
}

.vrtx-resources .vrtx-resource {
  margin-bottom: 40px;
}

.pdf-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    max-width: 350px;
    max-height: 250px;
    margin-right: 20px;
    border-radius: 20px;
  }

  h3 {
    &:hover {
      text-decoration: underline;
    }
  }
}

.vrtx-event-component-main {
  .vrtx-list-item-content {
    display: grid;

    .vrtx-event-component-title {
      order: 1;
    }

    .event-type {
      order: 2;
      margin-bottom: 15px;
    }

    .vrtx-event-component-misc {
      order: 3;
      margin-bottom: 0;
    }

    .vrtx-event-component-introduction {
      order: 4;
      p {
        .mixin-line-clamp;
      }
    }
  }
}

// Event feed
.description.introduction {
  p {
    .mixin-line-clamp;
  }
}

.collaborations-read-more {
  padding: 0 0 90px 0;
  width: 329px;
}

.vrtx-resource.vevent {
  .vrtx-list-item-content {
    display: grid;
  }
}

// Competition
.box-backgrounds {
  .vrtx-list-item-content {
    padding: 0px 20px 10px 20px;
  }
}

.vrtx-date-icon {
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: var(--color-teal);
  p,
  span,
  a {
    color: var(--color-white);
  }
}

// Buttons read more
.read-more {
  &.read-more-button2 {
    font-size: var(--font-size-h4);
    font-weight: 400;
    color: var(--color-extra-dark-blue);
  }
}

.read-more::before {
  margin-right: 30px;
}

.vrtx-more {
  span {
    a {
      font-size: var(--font-size-body-lenketekst);
      font-weight: 400;
    }
  }
}

// Button
.button {
  a {
    text-decoration: none;
  }

  &::after {
    background: url("../images/arrow-white.svg") no-repeat center;
    background-size: contain;
    padding-right: 10px;
  }

  &.button-custom {
    background: var(--color-teal);
    color: white;
    padding: 18px 24px;
    float: right;
    margin-right: -340px;
    margin-left: 25px;
    margin-bottom: 20px;
    width: 315px;
    clear: right;
    border-radius: 20px;

    @media (@screen-below-oversized) {
      display: flex;
      float: unset;
      width: unset;
      max-width: 315px;
      margin-left: 0;
      margin-right: 0px;
    }

    &#button-competition {
      width: 280px;
      margin-right: -330px;

      &::after {
        top: 23px;
      }
    }

    &#register-button {
      margin: 8px -305px 25px 20px;
    }

    &#dataset-button {
      a::before {
        content: "";
        background: url("../images/dataset.svg") no-repeat center;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
      }

      &::after {
        top: 25px;
      }
    }
  }
}

// Button large
.button-large {
  &#button-large-custom {
    background-color: var(--color-extra-dark-blue);
    border-radius: 20px;
    padding: 22px 80px 22px 25px;

    &::after {
      top: 40%;
    }
  }

  &.button-transparent {
    background: transparent;
    padding-left: 0px;
    font-size: var(--font-size-h4);
    font-weight: 700;
    width: 100%;
  }
}

#padding-small {
  margin-top: 15px;
}
