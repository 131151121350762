// oversized (above --width-total-max)
@screen-oversized: ~"min-width: 1441px";
@screen-below-oversized: ~"max-width: 1440px";

// x-people.less override
@screen-x-people-override: ~"min-width: 1101px";

// extra-large
@screen-xlarge: ~"min-width: 1041px";
@screen-below-xlarge: ~"max-width: 1040px";
// large
@screen-large: ~"min-width: 1001px";
@screen-below-large: ~"max-width: 1000px";

// medium+
@screen-medium-plus: ~"min-width: 851px";
@screen-below-medium-plus: ~"max-width: 850px";

// medium
@screen-medium: ~"min-width: 751px";
@screen-below-medium: ~"max-width: 750px";
// small
@screen-small: ~"min-width: 601px";
@screen-below-small: ~"max-width: 600px";
// extra-small
@screen-xsmall: ~"min-width: 361px";
@screen-below-xsmall: ~"max-width: 360px";

:root {
  // viewport
  //--width-total-max: 1144px;
  //--width-total: 1044px;
  //--width-body: 690px;

  // colors
  --color-black: #000000;
  --color-white: #ffffff;
  --color-dark-blue: #183051;
  --color-navy-blue: #244880;
  --color-royal-blue: #465d99;
  --color-sky-blue: #81b2e1;
  --color-teal: #017882;
  --color-aquamarine: #6fc3bb;
  --color-light-green: #badfd9;
  --color-yellow: #f3ec80;

  --color-light-blue: #f6fbff;
  --color-light-grey: #575757;
  --color-dark-grey: #262626;
  --color-darker-shade-gray: #333333;
  --color-light-blue2: #c4e5f8;
  --color-deep-blue: #2f3d89;
  --midnight-blue: #324c9c;
  --color-purple: #7d85c1;

  // font-sizes
  --font-size-h1: 5.4rem;
  --font-size-h1-small: 4.2rem;
  --font-size-h2: 3.6rem;
  --font-size-h2-small: 3.2rem;
  --font-size-h3: 2.8rem;
  --font-size-intro-ingress: 2.7rem;
  --font-size-h3-small: 2.4rem;
  --font-size-body-lenketekst: 2.4rem;
  --font-size-h4: 2.2rem;
  --font-size-body: 1.8rem;
  --font-size-body-small: 1.4rem;

  // line-height
  --line-height-1: 1.25;
  --line-height-2: 1.5;
  --line-height-3: 1.75;
}

/* Define the breakpoint for decreasing font sizes */
@media (@screen-below-small) {
  :root {
    --font-size-h1: 4.8rem;
    --font-size-h1-small: 3.6rem;
    --font-size-h2: 3.2rem;
    --font-size-h2-small: 2.8rem;
    --font-size-h3: 2.4rem;
    --font-size-intro-ingress: 2.3rem;
    --font-size-h3-small: 2rem;
    --font-size-body-lenketekst: 2rem;
    --font-size-h4: 1.8rem;
    --font-size-body: 1.4rem;
    --font-size-body-small: 1.2rem;
  }
}
